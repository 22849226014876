import React, {Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import {connect} from "react-redux";
import 'react-dates/initialize';
import '../../../styles/DatePicker.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import { store } from "../../../store/ConfigureStore";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'moment/locale/fr';
import {
    getDaysOff,
    getDaysOffExcep,
    setMonthIndicator,
    setShowExcepHolidayDetails,
    handleChangeCheckboxes
} from "../../../actions/ReferencesActions";
import BackupAutoComplete from '../holidays/addHolidayRequest/BackupAutoComplete';
import AddForEmployeeAutoComplete from './AddForEmployeeAutoComplete2';
import moment from 'moment-timezone';
import {$labelStyles, labelStyles} from "../../../styles/CommonStyles";
import {getIsProd, getLanguage, hasAuthority} from "../../../actions/AuthActions";
import './addRequest.css'
import ExceptionalDetailsDialog from "../holidays/addHolidayRequest/ExceptionalDetailsDialog";
import {strings} from "../../../i18n/Strings";
import { HALF_DAY, RETURN_DATE, LAST_DATE } from '../../../utilities/GlobalConstants';
import {formatString} from "../../../utilities/MappingFunctions";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'visible'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 500,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});



function getVO(type) {
    if (type === "PAIDHOLIDAY" || type == "RTTHOLIDAY" || type == "SENIORITYHOLIDAY" || type == "CASUALLEAVE") {
        return "MyCongeVO"
    }
    if (type === "EXCEPTIONALHOLIDAY") {
        return "ExceptionalCongeVO"
    }
    if (type === "UNPAIDHOLIDAY") {
        return "MyCongeVO"
    } else {
        return type
    }
}


function getMenuItems(items) {
    return items.map((item, index) => {
        if (item.type === "EXCEPTIONALHOLIDAY_IN_ROOT"){
            return <MenuItem name="EXCEPTIONALHOLIDAY_IN_ROOT" title={getExcepLabel(item.value.value)} key={index} value={item.value.value}>{item.label}</MenuItem>
        } else {
            return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
        }
    })
}

function getExcepLabel(e) {
    const {duration, provedByDocuments} = e;
    const {_DURRATION_OF_HOLIDAY, _REQUIRED_DOCS} = strings

    return formatString(_DURRATION_OF_HOLIDAY + _REQUIRED_DOCS, [
        duration,
        provedByDocuments.map(doc =>  " * " + doc.label).join("\n")
    ]);
}

function getMenuItemsWithToolTip(items) {
    return items.map((item, index) => {
            const {label, value} = item;
            return <MenuItem title={getExcepLabel(item.value)} key={index} value={item.value}>{label}</MenuItem>
        }
    )
}

class Selects extends Component {

    state = {
        "@type": "",
        duration: 0,
        totalDuration: 0,
        typeRequest: "REQUEST",
        typeConge: "",
        exceptionalType: "",
        startDate: null,
        endDate: null,
        multipleDates:false,
        focusedInputRange: null,
        focusedInputRange1: null,
        focusedInputRange2: null,
        focusedInputRange3: null,
        focused: false,
        hasBackup: false,
        addedForEmp: null,
        backup: null,
        comment: '',
        requiresEndDate: false,
        forFraction: false,
        doctorSpecialityId: null,
        doctorSpecialityLabel: null,
        doctorName: null,
        certificateDepositDate: null,
        selectedExcepHoliday: false,
        exceptionalTypeDetails: "",
        hasRoleMan: false,
        country: null,
        checkedPM: false,
        checkedAM: false,
        radioValue: null,
        startDate1: null,
        endDate1: null,
        halfLast:true,
        selectionedType : "",
        selectedExcepHolidayInRoot : false

    };

    async componentWillMount() {
        if (!this.props.addedClient) {
            const {auth, references} = this.props;
            const {profile} = auth;
            const {backup} = references;
            const {userName} = profile;
            const res = await getIsProd(userName);
            const isProd = false;
            this.setState({
                    addedForEmp: {
                        registrationNumber: userName,
                        prod: isProd,
                        role: [],
                        country: {
                            code: profile.countryCode
                        }
                    },
                    hasBackup: (backup.length > 0)
                },
            )
        }
        const demandesAjoutees = (window.location.pathname === 'DemandesAjoutees');
        const hasRoleMan = (hasAuthority(["ROLE_MAN"]) && !demandesAjoutees) && !hasAuthority(["ROLE_RH_CONGE"]);
        this.setState({...this.state});
        if(hasRoleMan && this.props.addedClient) {
            this.setState({"@type": "ExceptionalCongeVO",hasRoleMan, requiresEndDate: true,
                typeConge: "EXCEPTIONALHOLIDAY", comment: "Cette demande est de type maladie non rémunérée"})
        }
        this.props.handleChangeCheckboxes({checkedAM: false, checkedPM: false})
    }

    handleExcpeHolidayDetails = details => {

        const {doctorsSpeciality, doctorName, certificateDepositDate} = details;
        const {id, label} = doctorsSpeciality;

        this.setState({doctorSpecialityId: id, doctorSpecialityLabel: label, doctorName, certificateDepositDate});
    };

    Checkboxes = (event, name) => {

        if (name === "backup") {
            this.setState({backup: JSON.parse(event)}, () => {
                const {backup} = this.state;
                this.props.handleRequestChange({...this.state, backup});
            })
        }
        if (name === "exceptionalTypeInRoot") {
            this.setState({
                selectionedType: event.target.value,
                exceptionalType: event.target.value,
                "@type": getVO("EXCEPTIONALHOLIDAY"),
                typeConge: "EXCEPTIONALHOLIDAY",
                selectedExcepHolidayInRoot : true
            })
        }
        if (name === "typeConge") {
            this.setState({
                typeConge: event.target.value,
                "@type": getVO(event.target.value),
                exceptionalType: "",
                startDate: null,
                endDate: null,
                focusedInputRange: null,
                focused: false,
                comment: '',
                backup: null,
                selectedExcepHoliday: false,
                selectedExcepHolidayInRoot: false,
                exceptionalTypeDetails: "",
                multipleDates:false,
                selectionedType :event.target.value
            })
        } else {
            if (name !== "backup") {
                this.setState({[name]: event.target.value});
                console.log("show checkbox")
            }
        }
    };
    onDatesChange = (startDate, endDate) => {
        console.log("start date ", startDate);
        console.log("end date ", endDate);
        const {exceptionalType} = this.state;

        const {references} = this.props;
        const {configUser} = references;
        const lastDay = configUser && configUser.lastDay;
        console.log('lastDay', this.state);
        if (endDate == null) {
            this.setState({startDate: startDate, endDate: endDate});
            this.props.setMonthIndicator(startDate);
            return null;
        } else {
            this.setState({startDate: startDate, endDate: endDate, 'checkedAM': false, 'checkedPM': false});
            this.props.handleChangeCheckboxes({...this.state.checkedAM, ...this.state.checkedPM})
            this.props.setMonthIndicator(startDate);
            this.props.getDaysOff(moment(startDate).format("DD/MM/YYYY"), lastDay && lastDay != RETURN_DATE ? moment(endDate).add(1, 'days').format("DD/MM/YYYY") : moment(endDate).format("DD/MM/YYYY"), this.state.addedForEmp)
        }

    };

    onDateChange(startDate, isPaidHoliday = false) {
        const {exceptionalType} = this.state;
        this.props.setMonthIndicator(startDate);
        if(isPaidHoliday) {
            this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), null, "PAIDHOLIDAY", this.state.addedForEmp, (returnDate) => {
                this.setState({startDate, endDate: returnDate});
            });
        } else {
            this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), exceptionalType, "EXCEPTIONALHOLIDAY", this.state.addedForEmp);
            this.setState({startDate});
        }
    }

    submit = (name) => {
        console.log("name" , name ) ;
        if(name === "checkedAM" && this.state.checkedAM === false ){
            confirmAlert({
                title: '',
                message: 'Confirmez-vous souhaiter une après-midi de congé ?',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => {
                            this.setState({
                                halfLast :true  ,
                                checkedAM:true
                            })
                        }
                    },
                    {
                        label: 'Non',
                        onClick: () => {
                            this.setState({
                                halfLast :false ,
                                checkedAM:false
                            })
                        }

                    }
                ]
            });
        }


        if(name === "checkedPM"   && this.state.checkedPM === false){
            confirmAlert({
                title: '',
                message: 'Confirmez-vous souhaiter une matinée de congé ?',
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => {
                            this.setState({
                                halfLast :true  ,
                                checkedPM:true
                            })
                        }
                    },
                    {
                        label: 'Non',
                        onClick: () => {
                            this.setState({
                                halfLast :false ,
                                checkedPM:false
                            })
                        }

                    }
                ]
            });
        }

    };


    checkboxHandle  = async ( name , other , flag , event) =>{
        let countryCode = store.getState().auth.profile.countryCode;
        console.log("country code =>    " , countryCode ) ;
        if(countryCode=="FR"){
            await this.submit(name);
        }
        setTimeout(() => {  console.log("World!"); }, 2000);
        if(this.state.halfLast===true){
            if(flag){
                this.setState({ ...this.state, [name]: event, [other]: false });
                this.props.handleChangeCheckboxes({[name]: event, [other]: false })
            }else{
                this.setState({ ...this.state, [name]: event });
                this.props.handleChangeCheckboxes({[name]: event, [other]: this.state[other] });
            }
        }
        else  {
            this.setState({ checkedAM :false, [other]: false });
        }

    }

    handleChangeCheckboxes =  (name, other, flag) => ( event ) => {
        console.log("event==>"  ,event.target.checked) ;

        this.checkboxHandle(name,other,flag , event.target.checked);


    };



    handleAM_PM = event => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
        this.props.handleChangeCheckboxes({[event.target.value]: true})
    };




    render() {
        const {_typeRequest, _holidayPeriod, _holidayType, _startDate,
            _payedHoliday, _unpayedHoliday, _exceptionalHoliday, _exceptionalHolidayFr, _returnDate, _finishDate, _comment, _rttHoliday, _ancienneteHoliday, _am_label, _pm_label, _casualHoliday} = strings;
        const {classes, addedClient, references} = this.props;
        const {typeConge, exceptionalType, startDate, endDate,focusedInputRange,comment, requiresEndDate, selectedExcepHoliday, exceptionalTypeDetails, hasRoleMan, selectionedType, selectedExcepHolidayInRoot} = this.state;
        const {configUser} = references;
        
        let congeMappings = [
            {value: "PAIDHOLIDAY", label: _payedHoliday},
            {value: "UNPAIDHOLIDAY", label: _unpayedHoliday},
            {value: "EXCEPTIONALHOLIDAY", label: configUser.countryCode === '5' ? _exceptionalHolidayFr : _exceptionalHoliday}
        ];
        
        if(configUser) {
            if(configUser.ancienneteBalance) {
                congeMappings.push({value: "SENIORITYHOLIDAY", label: _ancienneteHoliday})
            }
            if(configUser.rttBalance) {
                congeMappings.push({value: "RTTHOLIDAY", label: _rttHoliday})
            }
            if(configUser.clvBalance) {
                congeMappings.push({value: "CASUALLEAVE", label: _casualHoliday})
            }
        }

        const {exceptTypeHr, exceptTypeHrUser} = this.props.references;
        const demandesAjoutees = !(window.location.pathname === 'DemandesAjoutees');
        this.props.handleRequestChange(this.state);
        const unitDay = configUser && configUser.unitDay;
        const lastDay = configUser && configUser.lastDay;
        const halfDayFraction = exceptionalType && exceptionalType.halfDayFraction;
        const forFraction = exceptionalType && exceptionalType.forFraction;
        // this.requiresEndDate = exceptionalType && exceptionalType.requiresEndDate || this.requiresEndDate;
        let multipleDaysCheckboxes;
        let exceptionalTypeInRoot = exceptTypeHr.filter(type => type.showInRoot == true && type.forJustify).map(type => {
            const lang =sessionStorage.getItem('language') != null ? sessionStorage.getItem('language') : 'fr';
            switch (lang){
                case 'fr':  return {value: type, label: type.label, type: "EXCEPTIONALHOLIDAY_IN_ROOT"  };
                case 'en':  return {value: type, label: type.labelEn , type: "EXCEPTIONALHOLIDAY_IN_ROOT"};
                case 'es':  return {value: type, label: type.labelEs , type: "EXCEPTIONALHOLIDAY_IN_ROOT"};
                case 'pt':  return {value: type, label: type.labelPt , type: "EXCEPTIONALHOLIDAY_IN_ROOT"};
            }
        });

        exceptionalTypeInRoot.forEach(ex => congeMappings.push({
            type: "EXCEPTIONALHOLIDAY_IN_ROOT",
            label: ex.label,
            value : ex
        })) ;
        console.log("congeMappings :", congeMappings);
        if (unitDay && unitDay == HALF_DAY){
            multipleDaysCheckboxes = <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked checked={this.state.checkedPM} onChange={this.handleChangeCheckboxes('checkedPM', 'checkedAM')} value={this.state.checkedPM.toString()} />
                    }
                    label={_pm_label}
                />
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked checked={this.state.checkedAM}
                                  onClick={this.handleChangeCheckboxes('checkedAM', 'checkedPM')}
                                  value={this.state.checkedAM.toString()} />}
                    label={_am_label}
                />
            </Grid>;
        }
        if(moment(startDate).isSame(moment(endDate))){
            multipleDaysCheckboxes = <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked checked={this.state.checkedPM} onChange={this.handleChangeCheckboxes('checkedPM', 'checkedAM', true)} value={this.state.checkedPM} />
                    }
                    label={_pm_label}
                />
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked checked={this.state.checkedAM} onChange={this.handleChangeCheckboxes('checkedAM', 'checkedPM', true)} value={this.state.checkedAM} />}
                    label={_am_label}
                />
            </Grid>;

        }

        return (
            <Grid container direction={"column"} spacing={16}>
                {
                    addedClient && <Grid item>
                        <AddForEmployeeAutoComplete handleChange={(employee) => {
                            const {registrationNumber, role, prod, country} = employee;
                            this.setState({
                                addedForEmp: {
                                    registrationNumber,
                                    prod,
                                    role,
                                    country
                                }
                            })

                            if(demandesAjoutees || !hasAuthority(["ROLE_MAN"])) {
                                this.setState({
                                    exceptionalType: {
                                        "provedByDocuments": [],
                                        "id": 139,
                                        "label": "Maladie Non Payée",
                                        "duration": 1,
                                        "roleEnum": "RH_VALIDATION",
                                        "requiresEndDate": true,
                                        "country": country,
                                        "mappingHR": {
                                            "id": 108,
                                            "label": "Maladie Non Payée",
                                            "abbreviation": "MAN",
                                            "source": "HR",
                                            "type": "UNPAIDHOLIDAY"
                                        },
                                        "forJustify": false
                                    }
                                })
                            }
                        }}/>
                    </Grid>
                }
                {
                    (!hasRoleMan) && <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={labelStyles}>
                                {_typeRequest}
                            </InputLabel>
                            <Select
                                disabled={hasRoleMan}
                                value={selectionedType}
                                onChange={(e) => {
                                    if(!(["PAIDHOLIDAY", "UNPAIDHOLIDAY", "RTTHOLIDAY", "SENIORITYHOLIDAY","CASUALLEAVE","EXCEPTIONALHOLIDAY"].includes(e.target.value))){
                                        var typeField = e.target.value;
                                        console.log("typeField :", typeField);
                                        const {startDate} = this.state;
                                        this.setState({
                                            requiresEndDate: false,
                                            selectedExcepHoliday: true,
                                            selectedExcepHolidayInRoot : true,
                                            exceptionalTypeDetails: getExcepLabel(typeField)
                                        });

                                        if (typeField.requiresEndDate) {
                                            this.setState({requiresEndDate: true})
                                        }

                                        if (startDate != null) {
                                            this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), e.target.value)
                                        }
                                        // test if the selected holiday type is sickness to show doctor details & certificate deposit date
                                        // if (typeField.id === 11) {
                                        //     this.props.setShowExcepHolidayDetails(true);
                                        // }
                                        this.Checkboxes(e, "exceptionalTypeInRoot")
                                    }else {
                                        this.Checkboxes(e, "typeConge")
                                    }
                                }}
                            >
                                {getMenuItems(congeMappings)}
                            </Select>
                        </FormControl>
                    </Grid>
                }

                <Grid item>
                    {
                        !addedClient && <BackupAutoComplete handleChange={this.Checkboxes}/>
                    }
                </Grid>

                {
                    ["EXCEPTIONALHOLIDAY"].includes(typeConge) && (!hasRoleMan) && !selectedExcepHolidayInRoot &&
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={labelStyles}
                            >
                                {_holidayType}
                            </InputLabel>
                            <Select
                                disabled={hasRoleMan}
                                value={exceptionalType}
                                onChange={(e) => {
                                    var typeField = e.target.value;
                                    const {startDate} = this.state;
                                    this.setState({
                                        requiresEndDate: false,
                                        selectedExcepHoliday: true,
                                        exceptionalTypeDetails: getExcepLabel(typeField)
                                    });

                                    if (typeField.requiresEndDate) {
                                        this.setState({requiresEndDate: true})
                                    }

                                    if (startDate != null) {
                                        this.props.getDaysOffExcep(moment(startDate).format("DD/MM/YYYY"), e.target.value)
                                    }
                                    // test if the selected holiday type is sickness to show doctor details & certificate deposit date
                                    // if (typeField.id === 11) {
                                    //     this.props.setShowExcepHolidayDetails(true);
                                    // }
                                    this.Checkboxes(e, "exceptionalType")

                                }}
                            >
                                {getMenuItemsWithToolTip(exceptTypeHr.filter(type => !type.showInRoot && type.forJustify).map(type => {
                                    const lang =sessionStorage.getItem('language') != null ? sessionStorage.getItem('language') : 'fr';
                                    switch (lang){
                                        case 'fr':  return {value: type, label: type.label};
                                        case 'en':  return {value: type, label: type.labelEn};
                                        case 'es':  return {value: type, label: type.labelEs};
                                        case 'pt':  return {value: type, label: type.labelPt};
                                    }
                                }))}

                            </Select>
                            {( exceptionalType.label === "Congé Paternité (naissance unique)"  ||  exceptionalType.label ==="Congé Paternité multiple( jumeaux,triplés...)" ) &&
                            <FormControlLabel
                                control={<Checkbox false />}   onChange={() => this.setState({multipleDates:!this.state.multipleDates})} label="Saisie fractionnée" />
                            }
                        </FormControl>

                    </Grid>
                }

                {
                    (["EXCEPTIONALHOLIDAY"].includes(typeConge) && !requiresEndDate && !forFraction) && !this.state.multipleDates &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="single-select">{_startDate}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <SingleDatePicker
                                        numberOfMonths={2}
                                        noBorder={true}
                                        block={true}
                                        withPortal={true}
                                        hideKeyboardShortcutsPanel={true}
                                        onDateChange={startDate => this.onDateChange(startDate)}
                                        onFocusChange={({focused}) => this.setState({focused})}
                                        isOutsideRange={(startDate) => {
                                            return false
                                        }}
                                        focused={this.state.focused}
                                        date={startDate}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Divider/>
                        </div>
                    </Grid>
                }
                {
                    (["EXCEPTIONALHOLIDAY"].includes(typeConge) && !requiresEndDate && forFraction) &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="single-select">{_startDate}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <DateRangePicker
                                        startDateId="start"
                                        endDateId="end"
                                        block={true}
                                        startDate={startDate}
                                        noBorder={true}
                                        withPortal={true}
                                        endDate={endDate}
                                        startDatePlaceholderText={_startDate}
                                        endDatePlaceholderText={lastDay && lastDay == LAST_DATE ?_finishDate : _returnDate}
                                        hideKeyboardShortcutsPanel={true}
                                        minimumNights={lastDay && lastDay == LAST_DATE ? 0 : 1}
                                        onDatesChange={({startDate, endDate}) => {
                                            this.onDatesChange(startDate, endDate)
                                        }}
                                        isOutsideRange={(startDate) => {
                                            return false
                                        }}
                                        focusedInput={focusedInputRange}
                                        onFocusChange={(focusedInputRange) => {
                                            this.setState({focusedInputRange})
                                        }}
                                    />
                                </Grid>
                                {halfDayFraction ? multipleDaysCheckboxes : ''}
                            </Grid>
                            <Divider/>
                            <Divider/>
                        </div>
                    </Grid>
                }

                {
                    (["UNPAIDHOLIDAY", "RTTHOLIDAY", "SENIORITYHOLIDAY","CASUALLEAVE"].includes(typeConge)
                        || (typeConge === "PAIDHOLIDAY" && this.state.addedForEmp.country.code != 'DO')
                        || (typeConge === "PAIDHOLIDAY" && this.state.addedForEmp.country.code === 'DO' && !this.state.addedForEmp.prod)
                        || this.state.multipleDates ||  (["EXCEPTIONALHOLIDAY"].includes(typeConge) && requiresEndDate)
                    ) &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="range-select">{_holidayPeriod}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <DateRangePicker
                                        startDateId="start"
                                        endDateId="end"
                                        block={true}
                                        startDate={startDate}
                                        noBorder={true}
                                        withPortal={true}
                                        endDate={endDate}
                                        startDatePlaceholderText={_startDate}
                                        endDatePlaceholderText={lastDay && lastDay == LAST_DATE ?_finishDate : _returnDate}
                                        hideKeyboardShortcutsPanel={true}
                                        minimumNights={lastDay && lastDay == LAST_DATE ? 0 : 1}
                                        onDatesChange={({startDate, endDate}) => {
                                            this.onDatesChange(startDate, endDate)
                                        }}
                                        isOutsideRange={(startDate) => {
                                            if (!hasAuthority(["ROLE_RH_CONGE"] )){
                                                if(((exceptionalType.label === "Congé de présence parentale" || exceptionalType.label === "Congé de solidarité familiale") && moment().diff(startDate, 'days') > 0 )) {
                                                    return true;

                                                }
                                                if((exceptionalType.label ==='Congé de paternité et d’accueil de l’enfant en cas d’hospitalisation de l’enfant' || exceptionalType.label === 'Congé proche aidant' ) && startDate && startDate.startOf('day').isBefore(moment().startOf('day'))){
                                                    return true;
                                                }
                                            }
                                            return false
                                        }}
                                        focusedInput={focusedInputRange}
                                        onFocusChange={(focusedInputRange) => {
                                            this.setState({focusedInputRange})
                                        }}
                                    />
                                </Grid>
                                {halfDayFraction ? multipleDaysCheckboxes : ''}
                                {["PAIDHOLIDAY", "UNPAIDHOLIDAY", "RTTHOLIDAY", "SENIORITYHOLIDAY"].includes(typeConge) ? multipleDaysCheckboxes : ''}
                            </Grid>
                        </div>
                        <Divider/>
                        <Divider/>
                    </Grid>
                }

                {
                    (typeConge === "PAIDHOLIDAY" && this.state.addedForEmp.country.code === 'DO' && this.state.addedForEmp.prod) &&
                    <Grid item>
                        <div>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <InputLabel
                                        style={{marginLeft: 10, fontSize: 11}}
                                        htmlFor="single-select">{_startDate}</InputLabel>
                                </Grid>
                                <Grid item>
                                    <SingleDatePicker
                                        numberOfMonths={2}
                                        noBorder={true}
                                        block={true}
                                        withPortal={true}
                                        hideKeyboardShortcutsPanel={true}
                                        onDateChange={startDate => this.onDateChange(startDate, true)}
                                        onFocusChange={({focused}) => this.setState({focused})}
                                        isOutsideRange={(startDate) => {
                                            return false
                                        }}
                                        focused={this.state.focused}
                                        date={startDate}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Divider/>
                        </div>
                    </Grid>
                }

                <Grid item>
                    <InputLabel
                        style={$labelStyles}
                    >
                        {_comment}
                    </InputLabel>
                    <br/>
                    <TextField
                        disabled={hasRoleMan}
                        value={comment}
                        onChange={e => {
                            this.Checkboxes(e, "comment")
                        }}
                        multiline
                        rows="4"
                        className={classes.formControl}
                        margin="normal"
                    />
                </Grid>
                {
                    selectedExcepHoliday && <Grid item>
                        <div className={"exceptional-holiday"}>
                            {exceptionalTypeDetails}
                        </div>
                    </Grid>
                }
                <ExceptionalDetailsDialog handleExcpeHolidayDetails={this.handleExcpeHolidayDetails}/>
            </Grid>
        );
    }
}

const mapStateTopProps = state => {
    return {references: state.references, auth: state.auth}
};

const mapActionsToprops = {
    getDaysOff, getDaysOffExcep, setMonthIndicator, setShowExcepHolidayDetails, handleChangeCheckboxes,
};

export default connect(mapStateTopProps, mapActionsToprops)(withStyles(styles)(Selects));


